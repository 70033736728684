define("client/pods/channels/shop/campaigns/bulk-discounts/model", ["exports", "ramda", "ramda-adjunct", "client/pods/base/model", "client/mixins/sortable-details", "client/mixins/date"], function (_exports, R, RA, _model, _sortableDetails, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend(_sortableDetails.default, _date.default, {
    defaults() {
      return {
        name: '',
        status: 'enabled',
        schedules: [],
        productTags: [],
        isShowDescriptions: true,
        isAllowWithPromoCode: true
      };
    },
    populate(data, attrs) {
      data.schedules = this._transformSchedules(data);
      this._super(data, attrs);
    },
    _transformRules(data) {
      const rules = R.propOr([], 'rules')(data);
      return R.map(rule => {
        return this.dataManager.setAsRecord({
          adapterName: 'channels/shop/campaigns/bulk-discounts/rules',
          data: rule
        });
      })(rules);
    },
    addRule(rule = {}) {
      const rules = this.getData('rules') || [];
      const ruleModel = this.dataManager.setAsRecord({
        adapterName: 'channels/shop/campaigns/bulk-discounts/rules',
        data: rule
      });
      rules.pushObject(ruleModel);
      this.setData('rules', rules);
    },
    _transformSchedules(data) {
      const schedules = R.propOr([], 'schedules')(data);
      return R.map(schedule => {
        return this.dataManager.setAsRecord({
          adapterName: 'channels/shop/campaigns/bulk-discounts/schedules',
          data: schedule
        });
      })(schedules);
    },
    addSchedule(schedule = {}) {
      const schedules = this.getData('schedules') || [];
      const scheduleModel = this.dataManager.setAsRecord({
        adapterName: 'channels/shop/campaigns/bulk-discounts/schedules',
        data: schedule
      });
      schedules.pushObject(scheduleModel);
      this.setData('schedules', schedules);
    },
    statusClass: Ember.computed('_data.status', function () {
      const status = this.getData('status');
      if (status === 'enabled') {
        return 'is-primary';
      }
      return 'is-danger';
    }),
    checkOverlapDate({
      bulkDiscount
    }) {
      const schedules = bulkDiscount.getData('schedules') || [];
      const emptyStartDateZSchedules = R.filter(R.pathEq(['_data', 'dateStartZ'], ''))(schedules);
      if (R.length(emptyStartDateZSchedules) > 1) {
        return {
          hasOverlap: true,
          ids: R.pipe(R.pluck('_data'), R.pluck('scheduleId'))(emptyStartDateZSchedules)
        };
      }
      const emptyDateEndZSchedules = R.filter(R.pathEq(['_data', 'dateEndZ'], ''))(schedules);
      if (R.length(emptyDateEndZSchedules) > 1) {
        return {
          hasOverlap: true,
          ids: R.pipe(R.pluck('_data'), R.pluck('scheduleId'))(emptyDateEndZSchedules)
        };
      }
      const emptyStartDateZSchedule = R.head(emptyStartDateZSchedules) || {};
      const emptyDateEndZSchedule = R.head(emptyDateEndZSchedules) || {};
      const scheduleWithoutEmptyDate = R.reject(R.anyPass([R.pathEq(['_data', 'startDateZ'], ''), R.pathEq(['_data', 'dateEndZ'], '')]))(schedules);
      const overlappedIds = [];
      const hasOverlap = R.pipe(R.sortBy(R.path(['_data', 'dateEndZ'])), R.sortBy(R.path(['_data', 'dateStartZ'])), R.prepend(emptyStartDateZSchedule), R.append(emptyDateEndZSchedule), R.reject(RA.isNilOrEmpty), RA.mapIndexed((data, i, sortedDatas) => {
        const dateEndZ = data.getData('dateEndZ') || '';
        let nextDateStartZ = '';
        const nextData = sortedDatas[i + 1];
        if (RA.isNotNilOrEmpty(nextData)) {
          nextDateStartZ = nextData.getData('dateStartZ');
          if (nextDateStartZ <= dateEndZ) {
            overlappedIds.push(data.getData('scheduleId'));
            overlappedIds.push(nextData.getData('scheduleId'));
            return true;
          }
        }
        return false;
      }), R.includes(true))(scheduleWithoutEmptyDate);
      return {
        hasOverlap,
        ids: R.uniq(overlappedIds)
      };
    }
  });
});